import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo/LogoWhite.png';

const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [Burger, setBurger] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavItemClick = () => {
    setBurger(true); // Collapse the navbar when an item is clicked
  };

  return (
    <header className={`header ${isSticky ? 'sticky' : ''}`} id="myHeader">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-6 col-md-4 col-lg-2">
            <div className="logo">
              <a href="#">
                <img src={logo} className="img-fluid" alt="Corban Catering" />
              </a>
            </div>
          </div>
          <div className="col-6 col-md-8 col-lg-10">
            <nav className="navbar navbar-expand-lg nabar-own p-0">
              <button
                className={`navbar-toggler ${Burger ? 'collapsed' : ''}`}
                onClick={() => {
                  setBurger(!Burger);
                }}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded={!Burger}
                aria-label="Toggle navigation"
                style={{ border: '1px solid white' }} // Add this style to set the border color to white
              >
                <span className="navbar-toggler-icon">
                  <span className="menu_line_1"></span>
                  <span className="menu_line_2"></span>
                  <span className="menu_line_3"></span>
                </span>
              </button>

              <div
                className={`collapse ${
                  Burger ? '' : 'show'
                } navbar-collapse tr-nabar-collapse`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto align-self-center">
                  <li className="nav-item tr-nav-item">
                    <a
                      className="nav-link"
                      href="#"
                      onClick={handleNavItemClick}
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item tr-nav-item">
                    <a
                      className="nav-link"
                      href="#about"
                      onClick={handleNavItemClick}
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item tr-nav-item">
                    <a
                      className="nav-link"
                      href="#Menu"
                      onClick={handleNavItemClick}
                    >
                      Menu
                    </a>
                  </li>
                  <li className="nav-item tr-nav-item">
                    <a
                      className="nav-link"
                      href="#gallery"
                      onClick={handleNavItemClick}
                    >
                      Gallery
                    </a>
                  </li>
                  <li className="nav-item tr-nav-item">
                    <a
                      className="nav-link"
                      href="#client"
                      onClick={handleNavItemClick}
                    >
                      Client
                    </a>
                  </li>
                  <li className="nav-item tr-nav-item">
                    <a
                      className="nav-link"
                      href="#Contact"
                      onClick={handleNavItemClick}
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
