import React from 'react';
import pattern from '../../assets/images/footer-images/Pattern.png';
const Footer = () => {
  return (
    <footer
      className="position-relative wow fadeIn"
      data-wow-duration="2s"
      data-wow-delay=".4s"
    >
      <div className="footer-bottom">
        <div className="container position-relative">
          {/* <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                    <div className="footer-menu text-center">
                        <ul>
                            <li className="nav-item"><a className="nav-link" href="#">Home</a>
                            </li>
                            <li className="nav-item"><a className="nav-link" href="#">About</a>
                            </li>
                            <li className="nav-item"><a className="nav-link" href="#">Story</a>
                            </li>
                            <li className="nav-item"><a className="nav-link" href="#">Booking</a>
                            </li>
                            <li className="nav-item"><a className="nav-link" href="#">Reviews</a>
                            </li>
                            <li className="nav-item"><a className="nav-link" href="#">TopFoods</a>
                            </li>
                        </ul>
                    </div>
                 </div>
            </div> */}
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 text-center">
              <div className="logo">
                {/* <img src={logo} className="img-fluid" alt="Corban Catering" /> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 footer-icon text-center">
              {/* <a className="fb-ic">
                <i
                  className="fab fa-facebook-f wow fadeInDown mr-md-5 mr-2"
                  data-wow-duration="1.5s"
                  data-wow-delay=".4s"
                >
                  {' '}
                </i>
              </a> */}
              {/* <a className="tw-ic">
                    <i className="fab fa-twitter wow fadeInUp mr-md-5 mr-2" data-wow-duration="1.5s" data-wow-delay=".4s"> </i>
                  </a> */}
              <a
                className="ins-ic"
                target="_blank"
                href="https://www.instagram.com/corbancatering.ca/"
              >
                <i
                  className="fab fa-instagram wow fadeInDown mr-md-5 mr-2"
                  data-wow-duration="1.5s"
                  data-wow-delay=".4s"
                ></i>
              </a>
              <a
                className="pin-ic"
                target="_blank"
                href="https://bit.ly/3UqiF9j"
              >
                <i
                  className="fas fa-map-marker-alt wow fadeInUp mr-md-5 mr-2"
                  data-wow-duration="1.5s"
                  data-wow-delay=".4s"
                ></i>
              </a>
              {/* <a className="sn-ic">
                    <i className="fab fa-snapchat wow fadeInDown mr-md-5 mr-2" data-wow-duration="1.5s" data-wow-delay=".4s"> </i>
                  </a> */}
            </div>
          </div>
          <div className="row copyright">
            <div className="col-md-12">
              <div className="footer-copylink text-center">
                <span>
                  <i className="fas fa-copyright"></i> All Rights Reserved by
                  Corban Catering 2024
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-pattern">
        <img src={pattern} className="pattern-img" alt="Pattern" />
      </div>
    </footer>
  );
};

export default Footer;
