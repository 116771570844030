import React from 'react';
// import dootd from '../../assets/images/background-shape/dottd-squre.png';

import T1 from '../../assets/images/testiomonial/WB.jpg';
import T2 from '../../assets/images/testiomonial/UNIVERSAL.jpg';
import T3 from '../../assets/images/testiomonial/CBS.jpg';
import T4 from '../../assets/images/testiomonial/DISNEY.jpg';
import T5 from '../../assets/images/testiomonial/NETFLIX.jpg';
import T6 from '../../assets/images/testiomonial/PARAMOUNT.jpg';
import T7 from '../../assets/images/testiomonial/Sony.png';
import T8 from '../../assets/images/testiomonial/FOX.png';

export default function Client() {
  return (
    <section className="testimonials position-relative" id="client">
      <h2 className="d-none">hidden</h2>
      <div className="shape-image-three">
        {/* <img src={dootd} className="img-fluid" alt="dots" /> */}
      </div>
      <div className="container">
        <div className="row specialities-top">
          <div className="col-2 col-md-2 col-lg-12">
            <div
              style={{ width: '100%', backgroundColor: 'white' }}
              className="wow fadeInDown"
              data-wow-duration="2s"
              data-wow-delay=".4s"
            >
              <h2 className="mb-0">Clients</h2>
            </div>
          </div>
        </div>
        <div className=" container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className="col-md-12 col-xs-12">
              <ul className="CLientlist">
                <li>
                  <img className="ClientImg" src={T1} alt="client" />
                </li>
                <li>
                  <img className="ClientImg" src={T2} alt="client" />
                </li>
                <li>
                  <img className="ClientImg" src={T3} alt="client" />
                </li>
                <li>
                  <img className="ClientImg" src={T4} alt="client" />
                </li>
                <li>
                  <img className="ClientImg" src={T5} alt="client" />
                </li>
                <li>
                  <img className="ClientImg" src={T6} alt="client" />
                </li>
                <li>
                  <img className="ClientImg" src={T7} alt="client" />
                </li>
                <li>
                  <img className="ClientImg" src={T8} alt="client" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
