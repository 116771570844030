import React from 'react';
import { Carousel, CarouselItem } from 'react-bootstrap';
import { useRef } from 'react';
import img1 from '../../assets/images/fullwidth-slider/1.jpg';
import img2 from '../../assets/images/fullwidth-slider/2.jpg';
import img3 from '../../assets/images/fullwidth-slider/3.jpg';
import img4 from '../../assets/images/fullwidth-slider/4.jpg';
import img5 from '../../assets/images/fullwidth-slider/5.jpg';

const Slider = () => {
  const ref = useRef(null);

  const onPrevClick = () => {
    ref.current.prev();
  };
  const onNextClick = () => {
    ref.current.next();
  };

  return (
    <div className="slider_fullwidth">
      {/* Previous Button */}
      <a onClick={onPrevClick}>
        <div
          className="slick-prev slick-arrow"
          style={{
            display: 'flex',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            top: '50%',
            left: '50px',
            zIndex: '50',
            lineHeight: '80px',
            fontSize: '20px',
            color: '#fffff',
          }}
        >
          <i className="fas fa-arrow-left" style={{ color: 'white' }}></i>
        </div>
      </a>
      {/* Next Button */}
      <a onClick={onNextClick}>
        <div
          className="slick-next slick-arrow"
          style={{
            display: 'flex',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            top: '50%',
            right: '50px',
            zIndex: '50',
            lineHeight: '80px',
            fontSize: '20px',
            color: '#fffff',
          }}
        >
          <i className="fas fa-arrow-right" style={{ color: 'white' }}></i>
        </div>
      </a>

      <Carousel
        indicators={false}
        interval={2750}
        controls={false}
        touch={true}
        pause={false}
        ref={ref}
      >
        {/* Slide 1 */}
        <CarouselItem>
          <section className="home-fullwidth-slider">
            <h1 className="d-none">hidden</h1>
            <div className="slider_fullwidth">
              <ul
                className="bx-slider_fullwidth p-0 carousel-inner"
                id="fullwidth_top_slider"
              >
                <li className="tp-slide slick-active slick-current">
                  <div className="slider-content-inner">
                    <div className="slider_bg_image">
                      <img
                        src={img1}
                        style={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </CarouselItem>
        {/* Slide 2 */}
        <CarouselItem>
          <section className="home-fullwidth-slider">
            <h1 className="d-none">hidden</h1>
            <div className="slider_fullwidth">
              <ul
                className="bx-slider_fullwidth p-0 carousel-inner"
                id="fullwidth_top_slider"
              >
                <li className="tp-slide slick-active slick-current">
                  <div className="slider-content-inner">
                    <div className="slider_bg_image">
                      <img
                        src={img2}
                        style={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </CarouselItem>
        {/* Slide 3 */}
        <CarouselItem>
          <section className="home-fullwidth-slider">
            <h1 className="d-none">hidden</h1>
            <div className="slider_fullwidth">
              <ul
                className="bx-slider_fullwidth p-0 carousel-inner"
                id="fullwidth_top_slider"
              >
                <li className="tp-slide slick-active slick-current">
                  <div className="slider-content-inner">
                    <div className="slider_bg_image">
                      <img
                        src={img3}
                        style={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </CarouselItem>
        {/* Slide 4 */}
        <CarouselItem>
          <section className="home-fullwidth-slider">
            <h1 className="d-none">hidden</h1>
            <div className="slider_fullwidth">
              <ul
                className="bx-slider_fullwidth p-0 carousel-inner"
                id="fullwidth_top_slider"
              >
                <li className="tp-slide slick-active slick-current">
                  <div className="slider-content-inner">
                    <div className="slider_bg_image">
                      <img
                        src={img4}
                        style={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </CarouselItem>
        {/* Slide 5 */}
        <CarouselItem>
          <section className="home-fullwidth-slider">
            <h1 className="d-none">hidden</h1>
            <div className="slider_fullwidth">
              <ul
                className="bx-slider_fullwidth p-0 carousel-inner"
                id="fullwidth_top_slider"
              >
                <li className="tp-slide slick-active slick-current">
                  <div className="slider-content-inner">
                    <div className="slider_bg_image">
                      <img
                        src={img5}
                        style={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'cover',
                        }}
                        alt="Image"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </CarouselItem>
      </Carousel>
    </div>
  );
};

export default Slider;
