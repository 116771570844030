import React from 'react';

import { Toaster } from 'react-hot-toast';
const ContactUs = ({ openModal }) => {
  return (
    <>
      <Toaster />
      <section
        className="email-subscription position-relative mt-5"
        id="Contact"
      >
        <div className="container mt-5">
          <div className="row justify-content-center ">
            <div className="col-12 col-md-10 col-lg-6 mt-5">
              <div className="custom-container">
                <button
                  onClick={openModal}
                  style={{
                    zIndex: '80',
                    background: 'none',
                    color: 'inherit',
                    border: 'none',
                    cursor: 'pointer',
                    outline: 'inherit',
                    width: '100%',
                  }}
                >
                  <span className="mt-0 contactus">Contact Us</span>
                </button>
              </div>
              {/* <form className="email-form">
              <div className="form-group position-relative mt-5">
              <input
              type="email"
              className="form-control"
              placeholder="Your Email Id"
              />
              <button
              type="submit"
              className="btn btn-primary email-form-button"
              >
              SEND
              </button>
              </div>
            </form> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
