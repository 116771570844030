import React, { useState, useRef, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Meditiran Pics
import M1 from '../../assets/images/our-item/Mediterranean/MediterraneanMenu-Packages.jpg';
import M2 from '../../assets/images/our-item/Mediterranean/MediterraneanMenu-ColdMezza.jpg';
import M3 from '../../assets/images/our-item/Mediterranean/MediterraneanMenu-HotMezza.jpg';
import M4 from '../../assets/images/our-item/Mediterranean/Image1.jpg';
import M5 from '../../assets/images/our-item/Mediterranean/MediterraneanMenu-FreshSalads.jpg';
import M6 from '../../assets/images/our-item/Mediterranean/MediterraneanMenu-Sandwiches.jpg';
import M7 from '../../assets/images/our-item/Mediterranean/MediterraneanMenu-Entrees.jpg';
import M8 from '../../assets/images/our-item/Mediterranean/Image2.jpg';
import M9 from '../../assets/images/our-item/Mediterranean/MediterraneanMenu-KabobBySkewer.jpg';
import M10 from '../../assets/images/our-item/Mediterranean/MediterraneanMenu-Seafood.jpg';
import M11 from '../../assets/images/our-item/Mediterranean/MediterraneanMenu-Sides.jpg';
import M12 from '../../assets/images/our-item/Mediterranean/MediterraneanMenu-Baklava.jpg';

//Meditiran PDFS
import K1 from '../../assets/pdf/Meditterian/MediterraneanMenu-Packages.pdf';
import K2 from '../../assets/pdf/Meditterian/MediterraneanMenu-ColdMezza.pdf';
import K3 from '../../assets/pdf/Meditterian/MediterraneanMenu-HotMezza.pdf';
import K5 from '../../assets/pdf/Meditterian/MediterraneanMenu-FreshSalads.pdf';
import K6 from '../../assets/pdf/Meditterian/MediterraneanMenu-Sandwiches.pdf';
import K7 from '../../assets/pdf/Meditterian/MediterraneanMenu-Entrees.pdf';
import K9 from '../../assets/pdf/Meditterian/MediterraneanMenu-KabobBySkewer.pdf';
import K10 from '../../assets/pdf/Meditterian/MediterraneanMenu-Seafood.pdf';
import K11 from '../../assets/pdf/Meditterian/MediterraneanMenu-Sides.pdf';
import K12 from '../../assets/pdf/Meditterian/MediterraneanMenu-Baklava.pdf';

// Production Pics
// import im1 from '../../assets/images/our-item/Production/image1.jpg';
// import im2 from '../../assets/images/our-item/Production/image2.jpg';
// import im3 from '../../assets/images/our-item/Production/image3.jpg';
// import im4 from '../../assets/images/our-item/Production/image4.jpg';

import P1 from '../../assets/images/our-item/Production/ProductionMenu-Breakfast.jpg';
import P2 from '../../assets/images/our-item/Production/ProductionMenu-MainsDoeuvres.jpg';
import P3 from '../../assets/images/our-item/Production/ProductionMenu-Sandwiches.jpg';
import P4 from '../../assets/images/our-item/Production/ProductionMenu-Entrees.jpg';
import P5 from '../../assets/images/our-item/Production/ProductionMenu-Mediterranean.jpg';
import P6 from '../../assets/images/our-item/Production/ProductionMenu-Italian.jpg';
import P7 from '../../assets/images/our-item/Production/ProductionMenu-Korean.jpg';
import P8 from '../../assets/images/our-item/Production/ProductionMenu-Mexican.jpg';
import P9 from '../../assets/images/our-item/Production/ProductionMenu-Salads.jpg';
import P10 from '../../assets/images/our-item/Production/ProductionMenu-Soups.jpg';
import P11 from '../../assets/images/our-item/Production/ProductionMenu-Bars&Snacks.jpg';
import P12 from '../../assets/images/our-item/Production/ProductionMenu-Sides.jpg';

// Production PDF
import PDF1 from '../../assets/pdf/Production/ProductionMenu-Breakfast.pdf';
import PDF2 from '../../assets/pdf/Production/ProductionMenu-MainsDoeuvres.pdf';
import PDF3 from '../../assets/pdf/Production/ProductionMenu-Sandwiches.pdf';
import PDF4 from '../../assets/pdf/Production/ProductionMenu-Entrees.pdf';
import PDF5 from '../../assets/pdf/Production/ProductionMenu-Mediterranean.pdf';
import PDF6 from '../../assets/pdf/Production/ProductionMenu-Italian.pdf';
import PDF7 from '../../assets/pdf/Production/ProductionMenu-Korean.pdf';
import PDF8 from '../../assets/pdf/Production/ProductionMenu-Mexican.pdf';
import PDF9 from '../../assets/pdf/Production/ProductionMenu-Salads.pdf';
import PDF10 from '../../assets/pdf/Production/ProductionMenu-Soups.pdf';
import PDF11 from '../../assets/pdf/Production/ProductionMenu-Bars&Snacks.pdf';
import PDF12 from '../../assets/pdf/Production/ProductionMenu-Sides.pdf';
//Individual Pics
import S1 from '../../assets/images/our-item/Individual/1.jpg';
import S2 from '../../assets/images/our-item/Individual/2.jpg';
import S3 from '../../assets/images/our-item/Individual/3.jpg';
import S4 from '../../assets/images/our-item/Individual/4.jpg';
import S5 from '../../assets/images/our-item/Individual/5.jpg';
import S6 from '../../assets/images/our-item/Individual/6.jpg';

// Individual PDF
import I1 from '../../assets/pdf/Individual/IndividualMenuBreakfast.pdf';
import I2 from '../../assets/pdf/Individual/IndividualMenuLaunchorDinner.pdf';
import I3 from '../../assets/pdf/Individual/IndividualMenuBitesAndMore.pdf';
import I4 from '../../assets/pdf/Individual/IndividualMenuSalads.pdf';
import I5 from '../../assets/pdf/Individual/IndividualMenuSandwiches.pdf';
import I6 from '../../assets/pdf/Individual/IndividualMenuWraps.pdf';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setIsMobile(windowWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};
const ItemSection = () => {
  const isMobile = useIsMobile();
  // Medditerian Menu ==>
  const [Medditerian, setMedditerian] = useState(false);
  // Production Menu ==>
  const [Production, setProduction] = useState(true);
  // Individual Menu ==>
  const [Individual, setIndividual] = useState(false);
  const [showAdditionalDiv, setShowAdditionalDiv] = useState(true);
  const [menuDescription, setMenuDescription] = useState('');
  const [menuTitle, SetMenuTitle] = useState('');
  const [subtitle, setSubtitle] = useState('color: #999999');

  // Set initial menu description for Mediterreaneasum
  useEffect(() => {
    SetMenuTitle('Production Menu :');
    setMenuDescription(
      'Introducing our production catering menu, designed to satisfy the appetites of cast and crew on film sets and of guests in various events and occasions. Our diverse selection of individual to-go boxes, cups and/or pans takes you on a gastronomic journey around the globe. From the vibrant flavors of the Mediterranean, the exotic spices of Asia, the soulful dishes of the South, the boldness of Mexican cuisine, the comforting classics of Italy, and the refined elegance of French gastronomy, among others; each menu is thoughtfully crafted to highlight the unique flavors and culinary traditions worldwide.'
    );
  }, []);

  // Production PDFS

  const openPdf1 = () => {
    window.open(PDF1, '_blank');
  };
  const openPdf2 = () => {
    window.open(PDF2, '_blank');
  };
  const openPdf3 = () => {
    window.open(PDF3, '_blank');
  };
  const openPdf4 = () => {
    window.open(PDF4, '_blank');
  };
  const openPdf5 = () => {
    window.open(PDF5, '_blank');
  };
  const openPdf6 = () => {
    window.open(PDF6, '_blank');
  };
  const openPdf7 = () => {
    window.open(PDF7, '_blank');
  };
  const openPdf8 = () => {
    window.open(PDF8, '_blank');
  };
  const openPdf9 = () => {
    window.open(PDF9, '_blank');
  };
  const openPdf10 = () => {
    window.open(PDF10, '_blank');
  };
  const openPdf11 = () => {
    window.open(PDF11, '_blank');
  };
  const openPdf12 = () => {
    window.open(PDF12, '_blank');
  };

  // MEdditerian PDFS
  const MPDF1 = () => {
    window.open(K1, '_blank');
  };
  const MPDF2 = () => {
    window.open(K2, '_blank');
  };
  const MPDF3 = () => {
    window.open(K3, '_blank');
  };
  const MPDF5 = () => {
    window.open(K5, '_blank');
  };
  const MPDF6 = () => {
    window.open(K6, '_blank');
  };
  const MPDF7 = () => {
    window.open(K7, '_blank');
  };
  const MPDF9 = () => {
    window.open(K9, '_blank');
  };
  const MPDF10 = () => {
    window.open(K10, '_blank');
  };
  const MPDF11 = () => {
    window.open(K11, '_blank');
  };
  const MPDF12 = () => {
    window.open(K12, '_blank');
  };
  // Individual Menu PDFS
  const IPDF1 = () => {
    window.open(I1, '_blank');
  };

  const IPDF2 = () => {
    window.open(I2, '_blank');
  };
  const IPDF3 = () => {
    window.open(I3, '_blank');
  };
  const IPDF4 = () => {
    window.open(I4, '_blank');
  };
  const IPDF5 = () => {
    window.open(I5, '_blank');
  };
  const IPDF6 = () => {
    window.open(I6, '_blank');
  };
  ////////////////////////////////////////////////// Actual COde
  const handleProductionClick = () => {
    setProduction(true);
    setMedditerian(false);
    setIndividual(false);
    setShowAdditionalDiv(true);
    setArrows(true);
    // Set menu description for Production sum
    SetMenuTitle('Production Menu :');
    setMenuDescription(
      'Introducing our production catering menu, designed to satisfy the appetites of cast and crew on film sets and of guests in various events and occasions. Our diverse selection of individual to-go boxes, cups and/or pans takes you on a gastronomic journey around the globe. From the vibrant flavors of the Mediterranean, the exotic spices of Asia, the soulful dishes of the South, the boldness of Mexican cuisine, the comforting classics of Italy, and the refined elegance of French gastronomy, among others; each menu is thoughtfully crafted to highlight the unique flavors and culinary traditions worldwide.'
    );
  };

  const handleMedditerianClick = () => {
    setProduction(false);
    setMedditerian(true);
    setIndividual(false);
    setShowAdditionalDiv(false);
    setArrows(true);
    // Set menu description for Mediterranean
    SetMenuTitle('Mediterranean Menu :');
    setMenuDescription(
      "Immerse yourself in a symphony of tastes, textures, and colors as you savor the Mediterranean's renowned dishes, each crafted with passion and authenticity. Whether it’s Tabouleh and Hummus, homemade shawarma, the famous kabob, seafood or diverse bites, you will embark on a delectable adventure that will transport your taste buds to the heart of this culinary paradise."
    );
  };

  const handleIndividualClick = () => {
    setProduction(false);
    setMedditerian(false);
    setIndividual(true);
    // setShowAdditionalDiv(true);
    setArrows(false);
    // Set Carousel Index to 0
    setCarouselIndex(0);
    //Set Subtitle Color ==>
    setSubtitle('color : white');
    // Set menu description for individual
    // TO BE CHANGED
    SetMenuTitle('Indiviudal Menu :');
    setMenuDescription(
      'Individual Servings for a Safer, More Efficient Dining Experience Our individual menu options provide each person with their own pre-portioned meal, eliminating the need for shared platters and communal serving utensils. This hygienic approach ensures that every dish is fresh, untouched, and tailored to the dietary needs and preferences of each guest.'
    );
  };

  const ref = useRef(null);

  const onPrevClick = () => {
    ref.current.prev();
  };
  const onNextClick = () => {
    ref.current.next();
  };

  //TO Disable Arrows When Individual is open
  const [Arrows, setArrows] = useState(true);
  // Set Carousel Index to 0 when individual clicked ==>
  const [CarouselIndex, setCarouselIndex] = useState(0);

  return (
    <section
      className="our-item-section position-relative specialities"
      id="Menu"
    >
      {isMobile && (
        <div
          style={{
            width: '100%',
            height: 75,
            backgroundColor: 'white',
            // margin: 20px 0;
            // padding: 30px 0;
          }}
        />
      )}
      {/* <h2 className="d-none">hidden</h2>
      <div className="shape-image-two">
        <img
          src={dottedsquare}
          className="img-fluid"
          alt="the background decorated "
        />
      </div> */}
      {/* <h2 className="d-none">hidden</h2>
      <div className="shape-image-two">
        <img
          src={dottedsquare}
          className="img-fluid"
          alt="the background decorated "
        />
      </div> */}
      <div
        style={{ width: '94%', marginLeft: '3%' }}
        //className="container"
      >
        <div className="row specialities-top">
          <div className="col-12 col-md-12 col-lg-12">
            <div
              className="wow fadeInDown"
              data-wow-duration="2s"
              data-wow-delay=".4s"
            >
              {isMobile ? (
                <h2 className="mb-0 mr-5 pe-2 ml-2  ">Menu</h2>
              ) : null}
            </div>
            {isMobile ? null : (
              <div
                className="row ms-5 ps-5 mx-auto ItemSectionCOntent"
                style={{ width: '100%', marginBottom: '2%' }}
              >
                <div className="col-12 col-md-4 col-lg-3 p-lg-0" />

                <div className="col-12 col-md-8 col-lg-8 p-lg-0">
                  <h3
                    style={{
                      fontSize: 28,
                      color: '#84001e',
                      fontWeight: 'bolder',
                    }}
                  >
                    MENU
                  </h3>
                  <h3 className="MenuTitle">{menuTitle}</h3>
                  <a className="SubMenu"> {menuDescription}</a>
                </div>
              </div>
              // <div  className="ItemSectionSUbMenu">
              //   <p className="MenuTitle"> {menuTitle} </p>
              //   <p className="SubMenu"> {menuDescription}</p>
              // </div>
            )}
          </div>
        </div>
        <div
          className="row ms-5 ps-5 mx-auto ItemSectionCOntent"
          style={{ width: '100%' }}
        >
          <div className="col-12 col-md-4 col-lg-3 p-lg-0">
            <ul
              className="our-item-tab nav nav-tabs flex-column pl-0 wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay=".4s"
            >
              <li className="nav-item">
                <a
                  className={'nav-link ' + (Production ? 'active' : '')}
                  onClick={handleProductionClick}
                  style={{ lineHeight: '70px' }}
                >
                  Production and Event Menu
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: '0.9em' }}
                  />
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={'nav-link ' + (Individual ? 'active' : '')}
                  onClick={handleIndividualClick}
                  style={{ lineHeight: '70px' }}
                >
                  Individual Menu
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: '0.9em' }}
                  />
                  <small className="subtitle" style={{ subtitle }}>
                    Minimum 25 pers
                  </small>               
                </a>
              </li>    
              <li className="nav-item">
                <a
                  className={'nav-link ' + (Medditerian ? 'active' : '')}
                  onClick={handleMedditerianClick}
                  style={{ lineHeight: '70px' }}
                >
                  Mediterranean Menu
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: '0.9em' }}
                  />
                </a>
              </li>          
            </ul>

            {/* the old Section  ==>>> */}
            {/* {!isMobile ? (
              <div className="ItemSectionPan">
                <a
                  style={{
                    color: 'white',
                    fontSize: 25,
                    textAlign: 'center',
                    alignSelf: 'center',
                    paddingRight: '10%',
                    paddingLeft: '10%',
                    textTransform: 'capitalize',
                    lineHeight: 1.2,
                  }}
                >
                  We Customize <br></br> your menu<br></br> upon your budget and
                  preferences.
                </a>
                <div
                  style={{
                    width: 100,
                    height: 35,
                    backgroundColor: 'white',
                    marginTop: '20%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p
                    className="mt-2"
                    style={{
                      color: '#84001e',
                      fontWeight: 'bolder',
                      textAlign: 'center',
                    }}
                  >
                    OUR PANS
                  </p>
                </div>
                <div
                  style={{
                    marginTop: '5%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  //
                >
                  <h5
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      fontWeight: 200,
                      fontSize: 16,
                    }}
                  >
                    SMALL PAN
                  </h5>
                  <h6 style={{ color: 'white', fontWeight: 'bold' }}>
                    10 TO 12 PEOPLE
                  </h6>
                </div>
                <div
                  style={{
                    marginTop: '2%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h5
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      fontWeight: 200,
                      fontSize: 16,
                    }}
                  >
                    MEDIUM PAN
                  </h5>
                  <h6 style={{ color: 'white', fontWeight: 'bold' }}>
                    18 TO 22 PEOPLE
                  </h6>
                </div>
                <div
                  style={{
                    marginTop: '2%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h5
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      fontWeight: 200,
                      fontSize: 16,
                    }}
                  >
                    LARGE PAN
                  </h5>
                  <h6 style={{ color: 'white', fontWeight: 'bold' }}>
                    30 TO 34 PEOPLE
                  </h6>
                </div>
              </div>
            ) : (
              <div
                style={{
                  // width: 300,
                  marginBottom: '20%',
                  // marginLeft: '10%',
                  marginTop: '5%',
                  height: '59%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#84001e',
                  alignSelf: 'center',
                }}
              >
                <a
                  style={{
                    color: 'white',
                    fontSize: 18,
                    textAlign: 'center',
                    alignSelf: 'center',
                    paddingRight: '10%',
                    paddingLeft: '10%',
                    textTransform: 'capitalize',
                    lineHeight: 1.2,
                  }}
                >
                  We Customize <br></br> your menu<br></br> upon your budget and
                  preferences.
                </a>
                <div
                  style={{
                    width: 100,
                    height: 35,
                    backgroundColor: 'white',
                    marginTop: '5%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p
                    className="mt-2"
                    style={{
                      color: '#84001e',
                      fontWeight: 'bolder',
                      textAlign: 'center',
                    }}
                  >
                    OUR PANS
                  </p>
                </div>
                <div
                  style={{
                    marginTop: '8%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h5
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      fontWeight: 200,
                      fontSize: 16,
                    }}
                  >
                    SMALL PAN
                  </h5>
                  <h6 style={{ color: 'white', fontWeight: 'bold' }}>
                    10 TO 12 PEOPLE
                  </h6>
                </div>
                <div
                  style={{
                    marginTop: '2%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h5
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      fontWeight: 200,
                      fontSize: 16,
                    }}
                  >
                    MEDIUM PAN
                  </h5>
                  <h6 style={{ color: 'white', fontWeight: 'bold' }}>
                    18 TO 22 PEOPLE
                  </h6>
                </div>
                <div
                  style={{
                    marginTop: '2%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h5
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      fontWeight: 200,
                      fontSize: 16,
                    }}
                  >
                    LARGE PAN
                  </h5>
                  <h6 style={{ color: 'white', fontWeight: 'bold' }}>
                    30 TO 34 PEOPLE
                  </h6>
                </div>
              </div>
            )} */}
          </div>

          {isMobile ? (
            <div>
              <p className="MenuTitle"> {menuTitle} </p>
              <p className="SubMenu"> {menuDescription}</p>
            </div>
          ) : null}
          <div className="col-12 col-md-8 col-lg-8 p-lg-0">
            <div className="tab-content" id="tabs">
              <Carousel
                indicators={false}
                interval={5000}
                controls={false}
                pause={false}
                touch={true}
                ref={ref}
              >
                {Medditerian && (
                  <Carousel.Item>
                    <div className="our-item-slider launch-slider">
                      <div className="our-item">
                        <div className="our-item-inner ">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={M1}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={MPDF1} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content">
                                {/* Add launch menu content */}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={M2}
                                  alt=""
                                  className="d-block w-100 "
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={MPDF2} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={M3}
                                  alt=""
                                  className="d-block w-100 "
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={MPDF3} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                          <div style={{ height: 15, width: '100%' }} />
                          <div className="row">
                            <div className="col-md-4 position-relative">
                              <div className="ImageContainer">
                                <img
                                  src={M4}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  style={{ zIndex: '-100' }}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={M5}
                                  alt="x"
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={MPDF5} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={M6}
                                  alt="x"
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={MPDF6} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                )}
                {Medditerian && (
                  <Carousel.Item>
                    <div className="our-item-slider breakfast-slider">
                      <div className="our-item">
                        <div className="our-item-inner">
                          <div className="row">
                            <div className="col-md-4 position-relative">
                              <div className="ImageContainer">
                                <img
                                  src={M7}
                                  className="d-block w-100"
                                  alt=""
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={MPDF7} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={M8}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  style={{ zIndex: '-10000' }}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={M9}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={MPDF9} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                          <div style={{ height: 15, width: '100%' }} />
                          <div className="row">
                            <div className="col-md-4 position-relative">
                              <div className="ImageContainer">
                                <img
                                  src={M10}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={MPDF10} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={M11}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={MPDF11} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={M12}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={MPDF12} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                )}
                {/* {breakfast && (
                  <Carousel.Item>
                    <div className="our-item-slider breakfast-slider">
                      <div className="our-item">
                        <div className="our-item-inner">
                          <div className="row">
                            <div className="col-md-6 position-relative">
                              <div className="ImageContainer">
                                <img
                                  src={M9}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={MPDF9} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-6">
                              <div className="ImageContainer">
                                <img
                                  src={M10}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={MPDF10} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                          <div style={{ height: 15, width: '100%' }} />
                          <div className="row">
                            <div className="col-md-6 position-relative">
                              <div className="ImageContainer">
                                <img
                                  src={M11}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={MPDF11} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-6">
                              <div className="ImageContainer">
                                <img
                                  src={M12}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={MPDF12} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                )} */}
                {Production && showAdditionalDiv && (
                  <Carousel.Item>
                    <div className="our-item-slider launch-slider">
                      <div className="our-item">
                        <div className="our-item-inner">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={P1}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf1}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content">
                                {/* Add launch menu content */}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={P2}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf2}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={P3}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf3}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                          <div style={{ height: 15, width: '100%' }} />
                          <div className="row">
                            <div className="col-md-4 position-relative">
                              <div className="ImageContainer">
                                <img
                                  src={P4}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf4}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={P5}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf5}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={P6}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf6}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                )}
                {Production && showAdditionalDiv && (
                  <Carousel.Item>
                    <div className="our-item-slider launch-slider">
                      <div className="our-item">
                        <div className="our-item-inner">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={P7}
                                  alt="x"
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf7}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content">
                                {/* Add launch menu content */}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={P8}
                                  alt=""
                                  className="d-block w-100 "
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf8}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={P9}
                                  alt=""
                                  className="d-block w-100 "
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf9}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                          <div style={{ height: 15, width: '100%' }} />
                          <div className="row">
                            <div className="col-md-4 position-relative">
                              <div className="ImageContainer">
                                <img
                                  src={P10}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf10}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={P11}
                                  alt="x"
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf11}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={P12}
                                  alt="x"
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf12}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                )}
                {/* {launch && showAdditionalDiv && (
                  <Carousel.Item>
                    <div className="our-item-slider launch-slider">
                      <div className="our-item">
                        <div className="our-item-inner">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="ImageContainer">
                                <img
                                  src={im3}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  style={{ zIndex: '-9999' }}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content">
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ImageContainer">
                                <img
                                  src={P7}
                                  alt=""
                                  className="d-block w-100 "
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf7}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                          <div style={{ height: 15, width: '100%' }} />
                          <div className="row">
                            <div className="col-md-6 position-relative">
                              <div className="ImageContainer">
                                <img
                                  src={P8}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf8}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-6">
                              <div className="ImageContainer">
                                <img
                                  src={P9}
                                  alt="x"
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf9}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                )} */}
                {/* {launch && showAdditionalDiv && (
                  <Carousel.Item>
                    <div className="our-item-slider launch-slider">
                      <div className="our-item">
                        <div className="our-item-inner">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="ImageContainer">
                                <img
                                  src={P10}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div
                                onClick={openPdf10}
                                className="our-item-content"
                              >
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ImageContainer">
                                <img
                                  src={P11}
                                  alt=""
                                  className="d-block w-100 "
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf11}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                          <div style={{ height: 15, width: '100%' }} />
                          <div className="row">
                            <div className="col-md-6 position-relative">
                              <div className="ImageContainer">
                                <img
                                  src={P12}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  onClick={openPdf12}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-6">
                              <div className="ImageContainer">
                                <img
                                  src={im4}
                                  alt="x"
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button
                                  style={{ zIndex: '-99999' }}
                                  className="MenuButton"
                                >
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                )} */}
                {Individual && (
                  <div>
                    <div className="our-item-slider launch-slider">
                      <div className="our-item">
                        <div className="our-item-inner">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={S1}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={IPDF1} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content">
                                {/* Add launch menu content */}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={S2}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={IPDF2} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={S3}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={IPDF3} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                          <div style={{ height: 15, width: '100%' }} />
                          <div className="row">
                            <div className="col-md-4 position-relative">
                              <div className="ImageContainer">
                                <img
                                  src={S4}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={IPDF4} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={S5}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={IPDF5} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                            <div className="col-md-4">
                              <div className="ImageContainer">
                                <img
                                  src={S6}
                                  alt=""
                                  className="d-block w-100"
                                  style={{ maxHeight: '100%', width: 'auto' }}
                                />
                                <button onClick={IPDF6} className="MenuButton">
                                  View Menu
                                </button>
                              </div>
                              <div className="our-item-content"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Carousel>
              {Arrows && (
                <div className="bx-controls-direction btnItem d-flex justify-content-end align-items-end">
                  <div className="controlphone">
                    <a className="bx-prev mr-2" onClick={onPrevClick}>
                      {/* Added mr-2 for right margin */}
                      <i className="fas fa-arrow-left"></i>
                    </a>
                    <a className="bx-next" onClick={onNextClick}>
                      <i className="fas fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ItemSection;
