import React, { useState } from 'react';
// import img1 from '../../assets/images/silders/dish.png';
import logo from '../../assets/gif/CorbanLogoAnimation.gif';

const HomeSlider = () => {
  const [gif, setGif] = useState(logo);
  setTimeout(() => {
    setGif(logo);
  }, 1000);

  return (
    <section className="home-slider" id="about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-lg-2">
            <div
              className="home-slider-image wow fadeInRight"
              data-wow-duration="2s"
              data-wow-delay=".4s"
            >
              <img
                src={gif}
                className="img-fluid"
                alt="tasty and healthy food made"
              />
            </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="slider-content">
              <span
                className="wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay=".3s"
              >
                Tasty & Healthy
              </span>
              <h1
                className="wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".4s"
              >
                Food Made With Love!
              </h1>
              <p
                className="mb-4 wow fadeInDown SubMenu"
                data-wow-duration="2s"
                data-wow-delay=".5s"
              >
                Corban Catering is a dynamic startup in the food industry,
                specializing in providing catering services to both production
                houses and individual occasions and events.
              </p>
              <div
                className="slider-video-section wow fadeInUp slider-obutton"
                data-wow-duration="1.5s"
                data-wow-delay=".4s"
              >
                <a href="#Menu" className="btn">
                  More Info
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSlider;
