import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import emailjs from '@emailjs/browser';

export default function Modal({ open, onClose }) {
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [desc, setDesc] = useState('');
  const [descLength, setDescLength] = useState(0); // State for text count

  //  Corban Credentials
  const serviceid = 'service_5atu3uo';
  const templateid = 'template_itkqw99';

  /////////////////////////

  const form = useRef();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const sendEmail = (event) => {
    event.preventDefault();
    const isValidEmail = validateEmail(email);
    if (!isValidEmail) {
      toast.error('Please enter a valid email address.');
      return;
    }
    const templateParams = {
      from_name: fullName,
      to_name: 'corban Catering',
      from_email: email,
      message: desc,
      subject: subject,
      phone: phone,
    };
    setButtonDisabled(true);
    emailjs
      .send(serviceid, templateid, templateParams, {
        publicKey: 'R5SsSeA1oyFCwpYMr',
      })
      .then((response) => {
        console.log('SUCCESS!', response);
        toast.success('Thank You!');
        onClose();
        setFullName('');
        setPhone('');
        setSubject('');
        setEmail('');
        setDesc('');
        setDescLength(0);
      })
      .catch((error) => {
        console.log('FAILED...', error.text);
        toast.error('Error');
      })
      .finally(() => {
        setButtonDisabled(false);
      });
  };
  const handlePhoneChange = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    setPhone(input);
  };
  const handleDescChange = (e) => {
    const text = e.target.value;
    setDesc(text);
    setDescLength(text.length);
    if (text.length === 500) {
      toast.error('You have reached the character limit.');
    }
  };
  if (!open) return null;
  return (
    <div className="ModalContainer">
      {/* <ToastContainer position={'top-start'}></ToastContainer> */}
      <div className="ModalHeader">
        <h1>Contact Us</h1>
        <FontAwesomeIcon
          icon={faTimes}
          size="2x"
          onClick={onClose}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <p>
        Or Call Us on: <a href="tel:(310)961-7178">(310)961-7178</a>{' '}
        <a href="tel:(904)635-8999">(904)635-8999</a>
      </p>
      <form onSubmit={sendEmail} ref={form}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <input
            type="text"
            placeholder="Full Name"
            className="ModalInput"
            required
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            minLength={2}
            maxLength={50}
          />
          <input
            type="tel"
            placeholder="Phone"
            className="ModalInput"
            required
            value={phone}
            onChange={handlePhoneChange}
            minLength={2}
            maxLength={12}
            title="Ten digits code"
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <input
            type="email"
            placeholder="Email*"
            className="ModalInput"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            minLength={2}
            maxLength={50}
          />
          <input
            type="text"
            placeholder="Subject*"
            className="ModalInput"
            required
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            maxLength={100}
            minLength={2}
          />
        </div>
        <textarea
          name="message"
          className="ModalInput1"
          rows={3}
          placeholder="Description*"
          maxLength={500}
          required
          value={desc}
          onChange={handleDescChange}
          style={{ resize: 'none' }}
        />
        <div
          style={{
            textAlign: 'right',
            fontSize: '0.8rem',
            marginTop: '0.2rem',
          }}
        >
          {descLength}/500
        </div>
        <button
          className="ModalbtnSubmit"
          type="submit"
          disabled={buttonDisabled}
        >
          {buttonDisabled ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  );
}
