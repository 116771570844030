import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './index.css';
import './media-query.css';
import './animate.css';
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
// Components ====>
// import ComingSoon from "./Pages/Components/ComingSoon";
import Navbar from './Pages/Components/Navbar';
import HomeSlider from './Pages/Components/HomeSlider';
import ContactUs from './Pages/Components/ContactUs';
import ItemSection from './Pages/Components/ItemSection';
import Footer from './Pages/Components/Footer';
import Gallery from './Pages/Components/Gallery';
// import WhatsappWidget from './Pages/Components/WhatsappWidget';
import Divider from './Pages/Components/Divider';
import Modal from './Pages/Components/Modal';
import Slider from './Pages/Components/Slider';
import Client from './Pages/Components/Client';

function App() {
  const [isOpen, SetisOpen] = useState(false);
  const openModal = () => {
    SetisOpen(true);
  };

  return (
    <>
      <Toaster />
      <Modal open={isOpen} onClose={() => SetisOpen(false)} />
      <div className={isOpen ? 'blur' : 'wrap'}>
        {/* <ComingSoon />  */}
        <Navbar />
        <Slider />
        <HomeSlider />
        {/* <Divider /> */}
        <ContactUs openModal={openModal} />
        <Divider />
        <ItemSection />
        <Divider />
        <Gallery />
        <Divider />
        <Divider />
        <Client />
        {/* <Divider /> */}
        <Footer />
        {/* <WhatsappWidget /> */}
      </div>
    </>
  );
}

export default App;
